import { CircularProgress } from '@mui/material';
import { Fragment, useState } from 'react';
import { Box } from '../../../../components/Containers/styles';
import { ListBody, ListDivider } from '../../../../components/List/styles';
import {
	PaginationBlock,
	PaginationButton,
} from '../../../../components/Pagination/styles';
import { getSearchUserByFilter } from '../../../../utils/search-user';
import { SearchUserForm } from '../SearchUserForm/SearchUserForm';

export const UsersList = () => {
	const [isFetching, setIsFetching] = useState(false);
	const [searchUsers, setSearchUsers] = useState([]);
	const [filter, setFilter] = useState(null);
	const [offset, setOffset] = useState(0);
	const [errors, setErrors] = useState([]);
	const limit = 50;
	const isPrevEnabled =
		searchUsers.length && searchUsers[0].Data.length && offset > 0;
	const isNextEnabled =
		searchUsers.length && searchUsers[0].Data.length === limit;

	const handleGetUsers = async (filter, offset, limit) => {
		setIsFetching(true);
		setFilter(filter);
		setSearchUsers([]);
		setErrors([]);
		try {
			const users = await getSearchUserByFilter({
				filter,
				offset,
				limit,
			});
			setSearchUsers([users]);
		} catch (error) {
			if (error.response && error.response.status === 400) {
				setErrors(error.response.data.detail.missing_value);
			} else {
				console.error(error);
			}
		} finally {
			setIsFetching(false);
		}
	};

	const handlePagination = newOffset => {
		if (
			Math.sign(newOffset) > 0 &&
			searchUsers.length &&
			searchUsers[0].Data.length &&
			searchUsers[0].Data.length === limit
		) {
			setOffset(offset + newOffset);
			handleGetUsers(filter, offset + newOffset, limit);
		}
		if (
			Math.sign(newOffset) < 0 &&
			searchUsers.length &&
			searchUsers[0].Data.length &&
			offset > 0
		) {
			setOffset(offset + newOffset);
			handleGetUsers(filter, offset + newOffset, limit);
		}
	};

	return (
		<Fragment>
			<SearchUserForm
				getUsers={filter => {
					setOffset(0);
					handleGetUsers(filter, 0, limit);
				}}
				reset={() => {
					setOffset(0);
					setSearchUsers([]);
				}}
				errors={errors}
			/>
			{searchUsers.length ? (
				<Fragment>
					<Box style={{ width: '100%', maxWidth: '1200px' }}>
						<ListBody gridColumns='1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'>
							<div className='header-wrapper'>
								<span className='header'>ECB Ref. No.</span>
							</div>
							<div className='header-wrapper'>
								<span className='header'>Last Name</span>
							</div>
							<div className='header-wrapper'>
								<span className='header'>First Name</span>
							</div>
							<div className='header-wrapper'>
								<span className='header'>DoB</span>
							</div>
							<div className='header-wrapper'>
								<span className='header'>Address</span>
							</div>
							<div className='header-wrapper'>
								<span className='header'>Role</span>
							</div>
							<div className='header-wrapper'>
								<span className='header'>Tracking Status</span>
							</div>
							<div className='header-wrapper'>
								<span className='header'>Status</span>
							</div>
							<div className='header-wrapper'>
								<span className='header'>Renewal Date</span>
							</div>
							{searchUsers[0].Data.map((user, index) => (
								<Fragment key={index}>
									<span className='body-item'>
										{user.Accountno}
									</span>
									<span className='body-item'>
										{user.Lastname}
									</span>
									<span className='body-item'>
										{user.Firstnames}
									</span>
									<span className='body-item'>
										{user.Dateofbirth}
									</span>
									<span className='body-item'>
										{user.Add1 +
											(user.Add2
												? ', ' + user.Add2
												: '') +
											(user.Add3
												? ', ' + user.Add3
												: '') +
											(user.City
												? ', ' + user.City
												: '') +
											(user.Postcode
												? ', ' + user.Postcode
												: '') +
											(user.County
												? ', ' + user.County
												: '')}
									</span>
									<span className='body-item'>
										{user.Role}
									</span>
									<span className='body-item'>
										{user.Trackingstatus}
									</span>
									<span className='body-item'>
										{user.Ecbstatus}
									</span>
									<span className='body-item'>
										{user.Renewaldate}
									</span>
									{index ===
									searchUsers[0].Data.length - 1 ? null : (
										<ListDivider span={9} />
									)}
								</Fragment>
							))}
						</ListBody>
					</Box>
					<PaginationBlock>
						<PaginationButton
							disabled={!isPrevEnabled}
							onClick={() =>
								isPrevEnabled && handlePagination(-limit)
							}
						>
							Previous
						</PaginationButton>
						<PaginationButton
							disabled={!isNextEnabled}
							onClick={() =>
								isNextEnabled && handlePagination(limit)
							}
						>
							Next
						</PaginationButton>
					</PaginationBlock>
				</Fragment>
			) : isFetching ? (
				<CircularProgress />
			) : null}
		</Fragment>
	);
};
