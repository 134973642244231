import styled from 'styled-components';

export const Style = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	h4 {
		margin-block-start: unset;
		margin-block-end: unset;
	}

	.info {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 48px 0;

		h5 {
			width: 600px;
			text-align: center;
			line-height: 24px;
		}

		h3 {
			margin-block-end: unset;
		}
	}
`;
