import { useEffect } from 'react';
import { CenteredContainer } from '../../components/Containers/styles';
import { useAuthListener } from '../../hooks/useAuthListener';
import { DashboardContent } from './components/DashboardContent';

export const Dashboard = () => {
	const { unsubscribe, fetchingUser } = useAuthListener();

	useEffect(() => {
		return () => {
			unsubscribe();
		};
	}, [unsubscribe]);

	return !fetchingUser ? (
		<CenteredContainer>
			<DashboardContent />
		</CenteredContainer>
	) : null;
};
