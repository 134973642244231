import styled, { css } from 'styled-components';
import { colors } from '../../styles/colors';

export const PaginationBlock = styled.div`
	display: flex;
	justify-content: center;
	gap: 24px;
`;

export const PaginationButton = styled.div`
	background-color: ${colors.PrimaryDark1};
	padding: 8px;
	border-radius: 4px;
	color: white;
	cursor: pointer;

	${({ disabled }) =>
		disabled &&
		css`
			background-color: ${colors.Neutral3};
			cursor: not-allowed;
		`}
`;
