import styled from 'styled-components';
import { colors } from '../../../styles/colors';

export const Style = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.info {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 20px 0;

		h5 {
			width: 600px;
			text-align: center;
			line-height: 24px;
		}

		h3 {
			margin-block: unset;
		}

		button {
			background-color: ${colors.Primary1};
			color: white;
			padding: 12px 20px;
			border-radius: 1000px;
			border: none;
			cursor: pointer;
		}
	}
`;
