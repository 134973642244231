import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonBlock } from '../../../../components/Buttons/styles';
import {
	Box,
	BoxDescription,
	CenteredContainer,
	InputBlock,
} from '../../../../components/Containers/styles';
import { DropdownInput } from '../../../../components/Dropdown/DropdownInput';
import { DatePicker } from '../../../../components/Inputs/DatePicker/DatePicker';
import { InputWithLabel } from '../../../../components/Inputs/InputWithLabel/InputWithLabel';
import { Input } from '../../../../components/Inputs/styles';
import { getCollection } from '../../../../firebase/db';
import { FIRESTORE } from '../../../../routes/routes';
import { colors } from '../../../../styles/colors';
import { getUserDoc } from '../../../../firebase/auth';
import { auth } from '../../../../firebase/initialize';
import { reformatDate } from '../../../../utils/format';
import { getUserProfile } from '../../../../utils/profile';
import { CircularProgress } from '@mui/material';

const inputState = {
	Strlastname: '',
	Strrole: '',
	Strpostcode: '',
	Strpublicstatus: '',
	Strecbstatus: '',
	Dob: '',
	County: '',
	Signatoriesonly: 0,
	Isguest: false,
	Isreader: false,
	Strfirstname: '',
};

export const SearchUserForm = ({ getUsers, reset = () => {}, errors }) => {
	const [status, setStatus] = useState([]);
	const [counties, setCounties] = useState([]);
	const [input, setInput] = useState(inputState);
	const [role, setRole] = useState(null);
	const [isFetching, setIsFetching] = useState(false);
	const [isECB, setIsECB] = useState(false);
	const navigate = useNavigate();
	const isFormEmpty =
		!input.Strfirstname &&
		!input.Strlastname &&
		!input.Strrole &&
		!input.Dob &&
		!input.Strpostcode &&
		!input.County &&
		!input.Strecbstatus;

	useEffect(() => {
		getCollection(FIRESTORE.STATUS)
			.then(status => setStatus(_.sortBy(status, 'name')))
			.catch(error => console.error(error));
		getCollection(FIRESTORE.COUNTIES)
			.then(counties => setCounties(_.sortBy(counties, 'name')))
			.catch(error => console.error(error));
	}, []);

	useEffect(() => {
		(async () => {
			const user = auth.currentUser;
			if (user) {
				setIsFetching(true);
				try {
					const userRole = await getUserDoc();
					setRole(userRole);
					const userProfile = await getUserProfile(user.uid);
					setIsECB(userProfile[0].county_id === 15);
				} catch (error) {
					console.error(error);
				} finally {
					setIsFetching(false);
				}
			}
		})();
	}, []);

	const handleInputChange = event => {
		const { name, value } = event.target;
		setInput({ ...input, [name]: value });
	};

	const handleReset = () => {
		setInput(inputState);
		navigate(0);
	};

	const hasError = value => {
		return errors && errors.includes(value);
	};

	const handleSave = () => {
		const newInput = { ...input };
		newInput.Strrole = input.Strrole.toLowerCase();

		if (input.County) {
			const countyName = counties
				.filter(county => county.id === Number(input.County))[0]
				.name.toLowerCase();
			newInput.County = countyName;
		}
		if (input.Strecbstatus) {
			const statusName = status
				.filter(status => status.id === Number(input.Strecbstatus))[0]
				.name.toLowerCase();
			newInput.Strecbstatus = statusName;
		}
		if (input.Dob) {
			newInput.Dob = reformatDate(newInput.Dob);
		}

		getUsers(newInput);
	};

	return (
		<Box style={{ width: '100%', maxWidth: '1200px' }}>
			<BoxDescription>Search</BoxDescription>
			{isFetching ? (
				<CenteredContainer style={{ padding: 40 }}>
					<CircularProgress />
				</CenteredContainer>
			) : (
				<InputBlock gridColumns='1fr 1fr 1fr'>
					<InputWithLabel required label='First Name'>
						<Input
							isError={hasError('Strfirstname')}
							value={input.Strfirstname}
							name='Strfirstname'
							onChange={handleInputChange}
						/>
					</InputWithLabel>
					<InputWithLabel required label='Surname'>
						<Input
							isError={hasError('Strlastname')}
							value={input.Strlastname}
							name='Strlastname'
							onChange={handleInputChange}
						/>
					</InputWithLabel>
					{isECB ? (
						<InputWithLabel label='Role'>
							<Input
								value={input.Strrole}
								name='Strrole'
								onChange={handleInputChange}
							/>
						</InputWithLabel>
					) : null}
					<InputWithLabel required label='DOB'>
						<DatePicker
							isError={hasError('Dob')}
							value={input.Dob}
							action={date => {
								setInput({ ...input, Dob: date });
							}}
						/>
					</InputWithLabel>
					{isECB ? (
						<InputWithLabel label='PostCode'>
							<Input
								value={input.Strpostcode}
								name='Strpostcode'
								onChange={handleInputChange}
							/>
						</InputWithLabel>
					) : null}
					{isECB ? (
						<InputWithLabel label='County'>
							<DropdownInput
								value={input.County}
								data={counties}
								name='County'
								onChange={handleInputChange}
							/>
						</InputWithLabel>
					) : null}
					{role &&
					isECB &&
					(role.role_id === 3 || role.role_id === 2) ? (
						<InputWithLabel label='Status'>
							<DropdownInput
								value={input.Strecbstatus}
								data={status}
								name='Strecbstatus'
								onChange={handleInputChange}
							/>
						</InputWithLabel>
					) : null}
				</InputBlock>
			)}
			<ButtonBlock>
				<Button
					disabled={isFormEmpty}
					bgColor={colors.Success}
					onClick={handleSave}
				>
					Search
				</Button>
				<Button bgColor={colors.Error} onClick={handleReset}>
					Reset
				</Button>
			</ButtonBlock>
		</Box>
	);
};
