import { StyledFlatpickr } from './styles';

export const DatePicker = ({
	format = 'd/m/Y',
	value,
	action = () => {},
	isError,
}) => {
	return (
		<StyledFlatpickr
			isError={isError}
			value={value}
			options={{
				dateFormat: format,
				allowInput: true,
				onClose: (selectedDates, dateStr, instance) => {
					action(dateStr);
				},
			}}
			onChange={(dates, date) => action(date)}
		></StyledFlatpickr>
	);
};
