import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { CenteredContainer } from '../../components/Containers/styles';
import { useAuthListener } from '../../hooks/useAuthListener';
import { ProfileForm } from './components/ProfileForm/ProfileForm';

export const Profile = () => {
	const { unsubscribe, fetchingUser, user } = useAuthListener();

	useEffect(() => {
		return () => {
			unsubscribe();
		};
	}, [unsubscribe]);

	return (
		<CenteredContainer>
			{fetchingUser && !user ? <CircularProgress /> : <ProfileForm />}
		</CenteredContainer>
	);
};
