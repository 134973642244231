export const createUrlParams = params => {
	if (typeof params !== 'object' || Object.keys(params).length === 0) {
		if (typeof params !== 'object') {
			throw new Error('Params must be an object');
		} else {
			throw new Error('Params object cannot be empty');
		}
	}

	const paramsObj = Object.fromEntries(
		Object.entries(params).map(([key, value]) => [
			key,
			encodeURIComponent(value),
		])
	);

	const urlSearchParams = new URLSearchParams(paramsObj);

	return '?' + decodeURIComponent(urlSearchParams.toString());
};
