import styled from 'styled-components';
import { colors } from '../../../styles/colors';

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;

	label {
		span {
			color: ${colors.Error};
		}
	}
`;
