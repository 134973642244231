import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import { FIRESTORE } from '../routes/routes';
import { firestore } from './initialize';

/**
 * It returns a promise that resolves to an array of documents from a given collection
 * @returns An array of objects.
 */
export const getCollection = async collectionName => {
	try {
		const docs = [];
		const querySnapshot = await getDocs(
			collection(firestore, collectionName)
		);
		querySnapshot.forEach(doc => {
			docs.push(doc.data());
		});

		return docs;
	} catch (error) {
		console.error(error);
	}
};

/**
 * Get all documents from the collection where the isActive field is true
 * @returns An array of objects.
 */
export const getActiveCollection = async (collectionName, activeName) => {
	try {
		const docs = [];
		const collectionQuery = query(
			collection(firestore, collectionName),
			where(activeName, '==', true)
		);
		const querySnapshot = await getDocs(collectionQuery);
		querySnapshot.forEach(doc => {
			docs.push(doc.data());
		});

		return docs;
	} catch (error) {
		console.error(error);
	}
};

/**
 * It takes a query and returns an array of documents
 * @returns An array of objects.
 */
export const getCollectionWithQuery = async query => {
	try {
		const docs = [];
		const querySnapshot = await getDocs(query);
		querySnapshot.forEach(doc => {
			docs.push(doc.data());
		});

		return docs;
	} catch (error) {
		console.error(error);
	}
};

/**
 * It takes an email address as an argument, queries the users collection for a document with a
 * user_email field that matches the email address, and returns an array of documents that match the
 * query
 * @returns An array of objects.
 */
export const getEmail = async email => {
	try {
		const docs = [];
		const emailQuery = query(
			collection(firestore, FIRESTORE.USERS),
			where('user_email', '==', email)
		);
		const querySnapshot = await getDocs(emailQuery);
		querySnapshot.forEach(doc => {
			docs.push(doc.data());
		});

		return docs;
	} catch (error) {
		console.error(error);
	}
};

/**
 * It takes a collection name, a list of objects, and a key to use as the document ID, and then it
 * creates a batch write and sets each object in the list as a document in the collection
 * @param collectionName - The name of the collection you want to write to.
 * @param [list] - an array of objects to be written to the database
 * @param key - The key to use to identify the document.
 */
export const setBatch = async (collectionName, list = [], key) => {
	try {
		const batch = writeBatch(firestore);

		list.forEach(item => {
			const docRef = doc(firestore, collectionName, `${item[key]}`);
			batch.set(docRef, item);
		});

		await batch.commit();
	} catch (error) {
		console.error(error);
	}
};

/**
 * It creates a document in a collection with a random id
 * @param collectionName - The name of the collection you want to create a document in.
 * @param data - The data you want to add to the document.
 */
export const createDocument = async (collectionName, data, docId) => {
	try {
		await setDoc(doc(firestore, collectionName, docId), { ...data });
	} catch (error) {
		console.error(error);
	}
};

export const getDocument = async (collectionName, docId, emptyValue) => {
	try {
		const docRef = doc(firestore, collectionName, docId);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			return docSnap.data();
		} else {
			return emptyValue;
		}
	} catch (error) {
		console.error(error);
	}
};

/**
 * It takes a collection name and data object, and updates the document in the collection with the id
 * of the data object
 * @param collectionName - The name of the collection you want to update the document in.
 * @param data - The data to be updated.
 */
export const updateDocument = async (collectionName, data, docId) => {
	try {
		const docRef = doc(firestore, collectionName, String(docId));
		await updateDoc(docRef, { ...data });
	} catch (error) {
		console.error(error);
	}
};
