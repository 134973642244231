export const ROUTES = {
	ROOT: '/',
	DASBOARD: 'dashboard',
	SEARCH_USER: 'search-user',
	ANNOUNCEMENTS: 'announcements',
	PROFILE: 'profile',
	USERS: 'users',
	LOGIN: 'login',
};

export const FIRESTORE = {
	STATUS: 'status',
	COUNTIES: 'counties',
	ROLE: 'role',
	USERS: 'users',
	ANNOUNCEMENTS: 'announcements',
};

export const STORAGE = {
	ANNOUNCEMENTS: 'announcements',
};

export const CLOUD_FUNCTIONS = {
	GET_SEARCH_USER_WITH_FILTER: 'https://dbs-checker-kw4d5x6tla-nw.a.run.app',
	// Development URL
	DEV_GET_SEARCH_USER_WITH_FILTER: 'http://localhost:8080',
};
