import { CenteredContainer } from '../../components/Containers/styles';
import { HomeContent } from './components/HomeContent';

export const Home = () => {
	return (
		<CenteredContainer>
			<HomeContent />
		</CenteredContainer>
	);
};
