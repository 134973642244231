import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/Buttons/styles';
import {
	AddNewBlock,
	CenteredContainer,
} from '../../components/Containers/styles';
import { getUserDoc } from '../../firebase/auth';
import { useAuthListener } from '../../hooks/useAuthListener';
import { UsersList } from './components/UsersList/UsersList';

export const Users = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const [role, setRole] = useState(null);

	const { unsubscribe, fetchingUser, user } = useAuthListener();

	useEffect(() => {
		return () => {
			unsubscribe();
		};
	}, [unsubscribe]);

	useEffect(() => {
		if (user) {
			getUserDoc()
				.then(userRole => {
					setRole(userRole);
				})
				.catch(error => console.error(error));
		}
	}, [user]);

	if (role && role.role_id !== 3) {
		navigate(-1);
		return;
	}

	return !fetchingUser ? (
		<CenteredContainer>
			{role && role.role_id === 3 ? (
				<AddNewBlock>
					<Button onClick={() => navigate(`${pathname}/new`)}>
						Add New User
					</Button>
				</AddNewBlock>
			) : null}
			<UsersList pathName={pathname} itemsPerPage={50} />
		</CenteredContainer>
	) : null;
};
