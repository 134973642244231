import { InputContainer } from './styles';

export const InputWithLabel = ({ children, label, required }) => {
	return (
		<InputContainer>
			<label>
				{label} {required ? <span>*</span> : null}
			</label>
			{children}
		</InputContainer>
	);
};
