export const colors = {
	// Primary Colors
	Primary1: '#0C336F',
	PrimaryLight1: '#465C9E',
	PrimaryDark1: '#000D43',

	// Secondary Colors
	Secondary1: '#FFBC99',
	SecondaryLight1: '#FFEFCA',
	SecondaryDark1: '#CA8C6B',

	// State Colors
	Success: '#4CAF50',
	Warning: '#F5C22B',
	Error: '#F44236',
	Info: '#04A9F5',

	// Grey Scales
	Neutral1: '#FAFAFA',
	Neutral2: '#EEEEEE',
	Neutral3: '#BDBDBD',
	Neutral4: '#757575',
	Neutral5: '#424242',
};
