/**
 * "Given a date in the format MM/DD/YYYY, return it in the format DD/MM/YYYY."
 *
 * The first thing we do is split the date into an array of three strings: the day, the month, and the
 * year. We do this by splitting the string on the forward slash character
 * @returns The date in the format of MM/DD/YYYY
 */
export const reformatDate = date => {
	const [day, month, year] = date.split('/');
	return `${month}/${day}/${year}`;
};
