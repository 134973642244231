import { ERROR } from '../constants/errors';

/**
 * It takes an error type and returns a string that describes the error
 * @returns A string
 */
export const loginError = errorType => {
	switch (errorType) {
		case ERROR.INVALID_EMAIL:
			return 'Invalid email address';
		case ERROR.EMPTY_EMAIL:
			return 'Empty email address';
		case ERROR.EMAIL_ALREADY_USED:
			return 'Email address already in use';
		case ERROR.USER_NOT_FOUND:
			return 'User not found';
		case ERROR.WRONG_PASSWORD:
			return 'Invalid password';
		case ERROR.EMPTY_PASSWORD:
			return 'Empty password';
		case ERROR.WEAK_PASSWORD:
			return 'Weak password';
		default:
			return '';
	}
};
