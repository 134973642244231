import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	padding: 12px 20px;
	gap: 8px;
	background-color: ${colors.Primary1};
	background-color: ${({ bgColor }) => bgColor};
	border-radius: 4px;
	border: unset;
	color: #fff;
	cursor: pointer;

	&:disabled {
		background-color: ${colors.Neutral3};
		cursor: not-allowed;
	}
`;

export const ButtonBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 20px;
`;
