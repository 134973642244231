import { LogoLettersECB } from '../../assets/icons/LogoLettersECB';
import { SearchUser } from '../../assets/icons/SearchUser';
import { Logout } from '../../assets/icons/Logout';
import { Profile } from '../../assets/icons/Profile';
import { Users } from '../../assets/icons/Users';

import { LinkContainer, LinkWithLabel, Main } from './styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/routes';
import { Login } from '../../assets/icons/Login';
import { getUserDoc, signInListener, signOutUser } from '../../firebase/auth';
import { Fragment, useState } from 'react';
import { useEffect } from 'react';

export const MenuBar = () => {
	const [user, setUser] = useState(null);
	const [role, setRole] = useState(null);
	let { pathname } = useLocation();
	const navigate = useNavigate();
	const newPathname = pathname.split('/')[1];

	useEffect(() => {
		let unsubscribe = () => {};
		unsubscribe = signInListener(setUser, () => {});
		return () => {
			unsubscribe();
		};
	}, [user]);

	useEffect(() => {
		if (user) {
			getUserDoc()
				.then(userRole => {
					setRole(userRole);
				})
				.catch(error => console.error(error));
		}
	}, [user]);

	const handleLogOut = () => {
		signOutUser();
		navigate(ROUTES.ROOT);
	};

	const NewLink = ({ children, route, isSameRoute }) => {
		return isSameRoute ? (
			<LinkWithLabel>
				<div className='flex'>{children}</div>
			</LinkWithLabel>
		) : (
			<LinkWithLabel>
				<Link to={route}>
					<div className='flex'>{children}</div>
				</Link>
			</LinkWithLabel>
		);
	};

	return (
		<Main>
			{user ? (
				<Fragment>
					<LinkContainer>
						<Link to={ROUTES.DASBOARD}>
							<div>
								<LogoLettersECB className='logo' />
							</div>
						</Link>
					</LinkContainer>
					<LinkContainer>
						<NewLink
							route={ROUTES.SEARCH_USER}
							isSameRoute={ROUTES.SEARCH_USER === newPathname}
						>
							<SearchUser className='icon' />
							DBS Search
						</NewLink>

						{role && role.role_id === 3 ? (
							<Fragment>
								<NewLink
									route={ROUTES.USERS}
									isSameRoute={ROUTES.USERS === newPathname}
								>
									<Users className='icon' />
									Users
								</NewLink>
							</Fragment>
						) : null}

						<NewLink
							route={ROUTES.PROFILE}
							isSameRoute={ROUTES.PROFILE === newPathname}
						>
							<Profile className='icon' />
							Profile
						</NewLink>
						<LinkWithLabel>
							<div className='flex' onClick={handleLogOut}>
								<Logout className='icon' />
								Logout
							</div>
						</LinkWithLabel>
					</LinkContainer>
				</Fragment>
			) : (
				<LinkContainer style={{ justifyContent: 'flex-end', flex: 1 }}>
					<LinkWithLabel>
						<Link to={ROUTES.LOGIN}>
							<div className='flex'>
								<Login className='icon' />
								Login
							</div>
						</Link>
					</LinkWithLabel>
				</LinkContainer>
			)}
		</Main>
	);
};
