import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const ListHeader = styled.div`
	display: grid;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	grid-template-rows: auto;
	font-size: 12px;
`;

export const ListBody = styled.div`
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	grid-template-rows: auto;
	overflow-x: scroll;

	.header-wrapper {
		display: flex;
		align-items: center;
		background-color: ${colors.PrimaryLight1};

		&.align-right {
			justify-content: end;
		}

		.header {
			padding: 12px 20px;
			color: white;
			font-size: 12px;
			font-weight: 600;
		}
	}

	.body-item {
		margin: 12px 20px;
		align-self: center;
	}

	.button {
		margin: 12px 20px;
	}

	.truncate {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	.ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export const ListDivider = styled.div`
	grid-column-start: span ${({ span }) => span};
	grid-column-end: span ${({ span }) => span};
	border-bottom: 1px solid ${colors.Neutral3};
`;
