import { CenteredContainer } from '../../components/Containers/styles';
import { auth } from '../../firebase/initialize';
import { LoginForm } from './components/LoginForm/LoginForm';

export const Login = () => {
	return (
		<CenteredContainer>
			<LoginForm />
		</CenteredContainer>
	);
};
