import { collection, orderBy, query, where } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { createUserAuth } from '../firebase/auth';
import {
	createDocument,
	getCollectionWithQuery,
	updateDocument,
} from '../firebase/db';
import { firestore } from '../firebase/initialize';
import { FIRESTORE } from '../routes/routes';

/**
 * "Get all users from the database, ordered by their email address."
 *
 * The first line of the function is a constant declaration. The constant is named `collectionQuery`
 * and it's value is the result of calling the `query` function. The `query` function takes two
 * arguments:
 *
 * 1. The first argument is the result of calling the `collection` function. The `collection` function
 * takes two arguments:
 * 	1. The first argument is the `firestore` object.
 * 	2. The second argument is the `FIRESTORE.USERS` constant.
 * 2. The second argument is the result of calling the `orderBy` function. The `orderBy` function takes
 * one argument:
 * 	1. The first argument is the string `user_email`
 * @returns An array of objects.
 */
export const getOrderedUsers = async () => {
	const collectionQuery = query(
		collection(firestore, FIRESTORE.USERS),
		where('uid', '!=', '')
		// orderBy('user_email')
	);

	const result = await getCollectionWithQuery(collectionQuery);

	return result;
};

/**
 * It creates a user in Firebase Auth and Firestore
 */
export const createUser = async user => {
	try {
		const { user_email, password, confirm_password, ...otherData } = user;
		const id = nanoid();
		const uid = await createUserAuth(user_email, password);
		const updatedUser = {
			...otherData,
			user_email,
			uid,
			user_id: id,
		};
		createDocument(FIRESTORE.USERS, updatedUser, id);
	} catch (error) {
		const errorMessage = {
			errorCode: error.code,
			errorMessage: error.message,
		};
		console.error(errorMessage);
		throw errorMessage;
	}
};

export const createAuthUserExistingUser = async user => {
	try {
		const { user_email, password, confirm_password, ...otherData } = user;
		const uid = await createUserAuth(user_email, password);
		const updatedUser = {
			...otherData,
			user_email,
			uid,
		};
		updateDocument(FIRESTORE.USERS, updatedUser, user.user_id);
	} catch (error) {
		const errorMessage = {
			errorCode: error.code,
			errorMessage: error.message,
		};
		console.error(errorMessage);
		throw errorMessage;
	}
};
