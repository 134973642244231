import axios from 'axios';
import { CLOUD_FUNCTIONS } from '../routes/routes';
import { createUrlParams } from './url';
import { getAuthToken } from './auth';

/**
 * It takes a filter object and returns a list of users
 * @returns The response.data is being returned.
 */
export const getSearchUserByFilter = async filter => {
	const { filter: filters, offset, limit } = filter;
	const { access_token } = await getAuthToken();
	const response = await axios.post(
		CLOUD_FUNCTIONS.GET_SEARCH_USER_WITH_FILTER +
			createUrlParams({ offset, limit }),
		filters,
		{
			headers: {
				access_token,
			},
		}
	);

	return response.data;
};
