import {
	onAuthStateChanged,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
} from 'firebase/auth';
import { collection, query, where } from 'firebase/firestore';
import { FIRESTORE } from '../routes/routes';
import { getCollectionWithQuery, getEmail, updateDocument } from './db';
import { auth, firestore } from './initialize';
import { ERROR } from '../constants/errors';
import axios from 'axios';

class UserNotFoundError extends Error {
	constructor(errorCode, errorMessage) {
		super(errorMessage);
		this.name = 'UserNotFoundError';
		this.code = errorCode;
	}
}

export const signIn = async ({ email, password }) => {
	try {
		const userCredential = await signInWithEmailAndPassword(
			auth,
			email,
			password
		);

		const userExist = await getEmail(email);

		if (!userExist.length) {
			throw new UserNotFoundError(
				ERROR.USER_NOT_FOUND,
				'User does not exist'
			);
		}

		if (!userExist[0].uid) {
			const uid = await createUserAuth(email, password);
			await updateDocument(
				FIRESTORE.USERS,
				{ ...userExist[0], uid },
				userExist[0].user_id
			);
		}

		const userObj = await userCredential;
		return userObj.user.uid;
	} catch (error) {
		const errorMessage = {
			errorCode: error.code,
			errorMessage: error.message,
		};
		console.error(errorMessage);
		throw errorMessage;
	}
};

export const signOutUser = async () => {
	try {
		await signOut(auth);
		return null;
	} catch (error) {
		console.error(error);
	}
};

export const signInListener = (setUser, setFetching) => {
	try {
		const unsubscribe = onAuthStateChanged(auth, user => {
			if (user) {
				setUser(user.uid);
				setFetching(false);
			} else {
				setUser(null);
				setFetching(false);
			}
		});

		return unsubscribe;
	} catch (error) {
		console.error(error);
	}
};

/**
 * It creates a user with the given email and password, and returns the user's uid
 * @param email - The email address of the user.
 * @param password - The password for the new account.
 * @returns The user's uid.
 */
export const createUserAuth = async (email, password) => {
	try {
		const {
			data: { uid },
		} = await axios.post(
			'https://us-central1-ecb-pmy-dbs-checker.cloudfunctions.net/createUser',
			{ email, password }
		);

		return uid;
	} catch (error) {
		console.error(error);
	}
};

/**
 * It gets the current user's document from the Firestore database
 * @returns The user document from the users collection.
 */
export const getUserDoc = async () => {
	try {
		const userRef = auth.currentUser;

		if (userRef.uid) {
			const collectionQuery = query(
				collection(firestore, FIRESTORE.USERS),
				where('uid', '==', userRef.uid)
			);
			const userDocs = await getCollectionWithQuery(collectionQuery);

			return userDocs[0];
		} else {
			return null;
		}
	} catch (error) {
		console.error(error);
	}
};

export const resetUserPassword = async email => {
	try {
		await sendPasswordResetEmail(auth, email);
	} catch (error) {
		const errorMessage = {
			errorCode: error.code,
			errorMessage: error.message,
		};
		console.error(errorMessage);
		throw errorMessage;
	}
};
