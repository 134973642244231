import { css } from 'styled-components';
import { colors } from './colors';
import arrowDown from '../assets/images/chevron-down-dark.svg';

export const input = {
	base: css`
		height: 24px;
		padding: 4px 8px;
		border-radius: 4px;
		border: 1px solid ${colors.Neutral4};

		&:focus {
			border: 1px solid ${colors.PrimaryDark1};
			box-shadow: none;
		}

		&:disabled {
			background-color: ${colors.Neutral3};
		}
	`,
	error: css`
		border: 2px solid ${colors.Error};
	`,
	select: css`
		height: 34px;
		background-image: url(${arrowDown});
		background-repeat: no-repeat;
		background-position: right 4px center;
		background-size: 20px;
		appearance: none;
	`,
};
