import { LogoECB } from '../../../assets/icons/LogoECB';
import { Style } from './styles';

export const DashboardContent = () => {
	return (
		<Style id='dashboard'>
			<LogoECB height='200px' />
			<h1>ECB DBS</h1>
			<h4>England & Wales Cricket Board</h4>
			<div className='info'>
				<h1>Information:</h1>
				<h4>Welcome to ECB DBS Checker</h4>
				<h5>
					This system is restricted to certain groups of authorised
					users within the cricket network. The information provided
					in this system is Strictly Confidential and should be only
					used for your Official Cricket role to ensure the
					Safeguarding of Young People and Adults at risk in Cricket.
					Permission to access this system is granted by the England &
					Wales Cricket Board (ECB) Safeguarding Team.
				</h5>
			</div>
		</Style>
	);
};
