import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInListener } from '../firebase/auth';
import { ROUTES } from '../routes/routes';

export const useAuthListener = () => {
	const [fetchingUser, setFetchingUser] = useState(true);
	const [user, setUser] = useState(null);
	const navigate = useNavigate();
	const unsubscribe = signInListener(setUser, setFetchingUser);

	useEffect(() => {
		if (!fetchingUser && !user) navigate('/' + ROUTES.LOGIN);
	});

	return { unsubscribe, fetchingUser, user };
};
