import { Route, Routes } from 'react-router-dom';

import { MenuBar } from './components/MenuBar/MenuBar';
import { SearchUser } from './pages/search-user/SearchUser';
import { Users } from './pages/users/Users';
import { UserForm } from './pages/users/components/UserForm/UserForm';
import { Profile } from './pages/profile/Profile';
import { MainContent } from './components/MainContent/styles';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Home } from './pages/home/Home';
import { Login } from './pages/login/Login';

import { ROUTES } from './routes/routes';

function App() {
	return (
		<main
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
			}}
		>
			<MenuBar />
			<MainContent>
				<Routes>
					<Route path={ROUTES.ROOT} element={<Home />} />
					<Route path={ROUTES.DASBOARD} element={<Dashboard />} />
					<Route path={ROUTES.SEARCH_USER} element={<SearchUser />} />
					<Route path={ROUTES.USERS} element={<Users />} />
					<Route
						path={ROUTES.USERS + '/:id'}
						element={<UserForm />}
					/>
					<Route path={ROUTES.PROFILE} element={<Profile />} />
					<Route path={ROUTES.LOGIN} element={<Login />} />
				</Routes>
			</MainContent>
		</main>
	);
}

export default App;
