import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Button, ButtonBlock } from '../../../../components/Buttons/styles';
import {
	Box,
	BoxDescription,
	InputBlock,
} from '../../../../components/Containers/styles';
import { DropdownInput } from '../../../../components/Dropdown/DropdownInput';
import { InputWithLabel } from '../../../../components/Inputs/InputWithLabel/InputWithLabel';
import { Input, InputError } from '../../../../components/Inputs/styles';
import { getCollection, updateDocument } from '../../../../firebase/db';
import { FIRESTORE } from '../../../../routes/routes';
import { colors } from '../../../../styles/colors';
import {
	getUserProfile,
	updateUserProfilePassword,
} from '../../../../utils/profile';
import { auth } from '../../../../firebase/initialize';
import { CircularProgress } from '@mui/material';
import { updateEmail } from 'firebase/auth';

export const ProfileForm = () => {
	const userObj = {
		county_id: 0,
		user_id: 0,
		role_id: 0,
		first_name: '',
		last_name: '',
		password: '',
		confirm_password: '',
		uid: '',
		is_active: true,
		user_email: '',
	};
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [role, setRole] = useState([]);
	const [counties, setCounties] = useState([]);
	const [isFetching, setIsFetching] = useState(false);
	const [user, setUser] = useState(userObj);

	const userAuth = auth.currentUser;
	const isPasswordEqual = user.confirm_password === user.password;

	useEffect(() => {
		getCollection(FIRESTORE.ROLE)
			.then(status => setRole(_.sortBy(status, 'name')))
			.catch(error => console.error(error));
		getCollection(FIRESTORE.COUNTIES)
			.then(counties => setCounties(_.sortBy(counties, 'name')))
			.catch(error => console.error(error));
	}, []);

	useEffect(() => {
		if (userAuth) {
			setIsFetching(true);
			getUserProfile(userAuth.uid)
				.then(userProfile => {
					setUser({ ...user, ...userProfile[0] });
					setIsFetching(false);
				})
				.catch(error => {
					setIsFetching(false);
					console.error(error);
				});
		}
	}, []);

	const handleInputChange = event => {
		const { name, value } = event.target;
		name === 'role_id' || name === 'county_id'
			? setUser({ ...user, [name]: Number(value) })
			: setUser({ ...user, [name]: value });
	};

	const handleSave = async () => {
		setLoading(true);
		const { password, confirm_password, ...otherData } = user;
		try {
			if (!password && !confirm_password) {
				await updateEmail(userAuth, otherData.user_email);
				await updateDocument(FIRESTORE.USERS, otherData, user.user_id);
			}
			if (password && confirm_password && isPasswordEqual) {
				await updateEmail(userAuth, otherData.user_email);
				await updateUserProfilePassword(userAuth, user);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return isFetching ? (
		<CircularProgress />
	) : (
		<Box style={{ width: '100%', maxWidth: '1000px' }}>
			<BoxDescription>Edit User</BoxDescription>
			<InputBlock gridColumns='1fr 1fr 1fr'>
				<InputWithLabel label='First Name' required>
					<Input
						value={user.first_name}
						name='first_name'
						onChange={handleInputChange}
					/>
				</InputWithLabel>
				<InputWithLabel label='Last Name' required>
					<Input
						value={user.last_name}
						name='last_name'
						onChange={handleInputChange}
					/>
				</InputWithLabel>
				<InputWithLabel label='Email' required>
					<Input
						value={user.user_email}
						name='user_email'
						type='email'
						onChange={handleInputChange}
					/>
				</InputWithLabel>
				{user && user.role_id === 3 ? (
					<InputWithLabel label='Role' required>
						<DropdownInput
							value={user.role_id}
							name='role_id'
							data={role}
							onChange={handleInputChange}
						/>
					</InputWithLabel>
				) : null}
				<InputWithLabel label='New Password'>
					<Input
						value={user.password}
						name='password'
						type='password'
						onChange={handleInputChange}
						isError={!isPasswordEqual}
					/>
					<InputError isError={!isPasswordEqual}>
						Password is not the same
					</InputError>
				</InputWithLabel>
				<InputWithLabel label='Password Confirmation'>
					<Input
						value={user.confirm_password}
						name='confirm_password'
						type='password'
						onChange={handleInputChange}
						isError={!isPasswordEqual}
					/>
					<InputError isError={!isPasswordEqual}>
						Password is not the same
					</InputError>
				</InputWithLabel>
				{user && user.role_id === 3 ? (
					<InputWithLabel label='County' required>
						<DropdownInput
							value={user.county_id}
							name='county_id'
							data={counties}
							required
							onChange={handleInputChange}
						/>
					</InputWithLabel>
				) : null}
			</InputBlock>
			<ButtonBlock>
				<Button
					bgColor={colors.Success}
					onClick={loading ? () => {} : handleSave}
				>
					{loading ? (
						<Fragment>
							<CircularProgress size={16} color='inherit' />
							Updating
						</Fragment>
					) : (
						'Save'
					)}
				</Button>
				<Button bgColor={colors.Error} onClick={() => navigate(-1)}>
					Cancel
				</Button>
			</ButtonBlock>
		</Box>
	);
};
