import { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Button } from '../../../../components/Buttons/styles';
import { Box } from '../../../../components/Containers/styles';
import { ListBody, ListDivider } from '../../../../components/List/styles';
import { getCollection, updateDocument } from '../../../../firebase/db';
import { FIRESTORE } from '../../../../routes/routes';
import { colors } from '../../../../styles/colors';
import { CircularProgress } from '@mui/material';
import { PaginateStyle } from '../../../../components/Paginate/styles';
import { SearchUserForm } from '../SearchUserForm/SearchUserForm';
import { getOrderedUsers } from '../../../../utils/users';

export const UsersList = ({ pathName, itemsPerPage }) => {
	const navigate = useNavigate();
	const [isFetching, setIsFetching] = useState(false);
	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [roles, setRoles] = useState([]);
	const [counties, setCounties] = useState([]);
	const [filter, setFilter] = useState({ email: '', name: '' });
	const getUser = () => {
		setIsFetching(true);
		getOrderedUsers()
			.then(collection => {
				setUsers(collection);
				setFilteredUsers(collection);
				setIsFetching(false);
			})
			.catch(error => {
				console.error(error);
				setIsFetching(false);
			});
	};

	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);

	// Simulate fetching items from another resources.
	// (This could be items from props; or items loaded in a local state
	// from an API endpoint with useEffect and useState)
	const endOffset = itemOffset + itemsPerPage;
	const currentUsers = filteredUsers.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);

	useEffect(() => {
		if (roles.length && counties.length) {
			getUser();
		}
	}, [roles, counties]);

	useEffect(() => {
		setIsFetching(true);
		getCollection(FIRESTORE.ROLE)
			.then(collection => {
				const sortedCollection = _.orderBy(collection, 'name', 'asc');
				setRoles(sortedCollection);
				setIsFetching(false);
			})
			.catch(error => {
				console.error(error);
				setIsFetching(false);
			});
	}, []);

	useEffect(() => {
		setIsFetching(true);
		getCollection(FIRESTORE.COUNTIES)
			.then(collection => {
				const sortedCollection = _.orderBy(collection, 'name', 'asc');
				setCounties(sortedCollection);
				setIsFetching(false);
			})
			.catch(error => {
				console.error(error);
				setIsFetching(false);
			});
	}, []);

	const getValue = (list, renderedId) => {
		if (roles.length) {
			const value = list.find(item => item.id === renderedId);
			return value ? value.name : '';
		}

		return '';
	};

	// Invoke when user click to request another page.
	const handlePageClick = event => {
		const newOffset =
			(event.selected * itemsPerPage) % filteredUsers.length;
		setItemOffset(newOffset);
	};

	const handleFilter = () => {
		const filteredUsers = _.filter(
			users,
			user =>
				(user.first_name
					.toLowerCase()
					.includes(filter.name.toLowerCase()) ||
					user.last_name
						.toLowerCase()
						.includes(filter.name.toLowerCase())) &&
				user.user_email
					.toLowerCase()
					.includes(filter.email.toLowerCase())
		);

		setFilteredUsers(filteredUsers);
	};

	const handleReset = () => {
		setFilteredUsers(users);
		setFilter({ email: '', name: '' });
	};

	const handleInputChange = event => {
		const { name, value } = event.target;
		setFilter({ ...filter, [name]: value });
	};

	const handleDelete = data => {
		setIsFetching(true);
		updateDocument(FIRESTORE.USERS, data, data.user_id)
			.then(() => {
				getUser();
			})
			.catch(error => {
				console.error(error);
				setIsFetching(false);
			});
	};

	return isFetching ? (
		<CircularProgress />
	) : (
		<Fragment>
			<SearchUserForm
				clearFilter={handleReset}
				setFilter={handleFilter}
				setValue={handleInputChange}
				value={filter}
			/>
			<Box style={{ width: '100%', maxWidth: '1200px' }}>
				<ListBody gridColumns='1.2fr 1.2fr 0.7fr 0.4fr 0.7fr 0.4fr'>
					<div className='header-wrapper'>
						<span className='header'>Email</span>
					</div>
					<div className='header-wrapper'>
						<span className='header'>Name</span>
					</div>
					<div className='header-wrapper'>
						<span className='header'>County</span>
					</div>
					<div className='header-wrapper'>
						<span className='header'>Role</span>
					</div>
					<div className='header-wrapper'>
						<span className='header'>Approve</span>
					</div>
					<div className='header-wrapper'>
						<span className='header'>Actions</span>
					</div>
					{currentUsers.map((user, index) => (
						<Fragment key={index}>
							<span className='body-item ellipsis'>
								{user.user_email}
							</span>
							<span className='body-item truncate'>{`${user.first_name} ${user.last_name}`}</span>
							<span className='body-item'>
								{getValue(counties, user.county_id)}
							</span>
							<span className='body-item'>
								{getValue(roles, user.role_id)}
							</span>
							<Button
								className='button'
								style={{
									backgroundColor: user.is_active
										? colors.Error
										: colors.Primary1,
								}}
								onClick={() =>
									handleDelete({
										user_id: user.user_id,
										is_active: !user.is_active,
									})
								}
							>
								{user.is_active ? 'De-activate' : 'Approve'}
							</Button>
							<Button
								className='button'
								onClick={() =>
									navigate(`${pathName}/${user.user_id}`)
								}
							>
								Edit
							</Button>
							{index === user.length - 1 ? null : (
								<ListDivider span={6} />
							)}
						</Fragment>
					))}
				</ListBody>
			</Box>
			<PaginateStyle>
				<ReactPaginate
					breakLabel='...'
					nextLabel='Next'
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					previousLabel='Previous'
					renderOnZeroPageCount={null}
				/>
			</PaginateStyle>
		</Fragment>
	);
};
