import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonBlock } from '../../../../components/Buttons/styles';
import {
	Box,
	BoxDescription,
	InputBlock,
} from '../../../../components/Containers/styles';
import { InputWithLabel } from '../../../../components/Inputs/InputWithLabel/InputWithLabel';
import { Input, InputError } from '../../../../components/Inputs/styles';
import { colors } from '../../../../styles/colors';
import {
	resetUserPassword,
	signIn,
	signInListener,
} from '../../../../firebase/auth';
import { ROUTES } from '../../../../routes/routes';
import { ERROR } from '../../../../constants/errors';
import { CircularProgress } from '@mui/material';
import { loginError } from '../../../../utils/errors';

export const LoginForm = () => {
	const navigate = useNavigate();
	const [login, setLogin] = useState({ email: '', password: '' });
	const [errorLogin, setErrorLogin] = useState(null);
	const [loading, setLoading] = useState(false);
	const [fetchingUser, setFetchingUser] = useState(true);
	const [showResetMessage, setShowResetMessage] = useState(false);
	const [user, setUser] = useState(null);

	const isEmailError =
		errorLogin === ERROR.INVALID_EMAIL ||
		errorLogin === ERROR.USER_NOT_FOUND ||
		errorLogin === ERROR.EMPTY_EMAIL;
	const isPasswordError =
		errorLogin === ERROR.WRONG_PASSWORD ||
		errorLogin === ERROR.EMPTY_PASSWORD;
	const isFormEmpty = !login.email && !login.password;

	useEffect(() => {
		let unsubscribe = () => {};
		unsubscribe = signInListener(setUser, setFetchingUser);
		if (user) navigate('/' + ROUTES.DASBOARD);
		return () => {
			unsubscribe();
		};
	}, [user, navigate]);

	const handleInputChange = event => {
		event.preventDefault();
		const { value, name } = event.target;
		setLogin({ ...login, [name]: value });
	};

	const handleResetPassword = async event => {
		try {
			event.preventDefault();
			if (!login.email) return setErrorLogin(ERROR.EMPTY_EMAIL);

			await resetUserPassword(login.email);
			setShowResetMessage(true);
		} catch (error) {
			setErrorLogin(error.errorCode);
			setShowResetMessage(false);
		}
	};

	const handleLogin = async () => {
		try {
			setLoading(true);
			if (!login.password) return setErrorLogin(ERROR.EMPTY_PASSWORD);
			if (!login.email) return setErrorLogin(ERROR.EMPTY_EMAIL);
			await signIn(login);
			setLoading(false);
		} catch (error) {
			setErrorLogin(error.errorCode);
			setLoading(false);
		}
	};

	return !fetchingUser ? (
		<Fragment>
			{showResetMessage ? (
				<Box style={{ width: '100%', maxWidth: '1000px' }}>
					<InputBlock style={{ textAlign: 'center', fontSize: 24 }}>
						Please check your email to reset your password
					</InputBlock>
					<InputBlock
						style={{ display: 'flex', justifyContent: 'center' }}
					>
						<Button
							disabled={isFormEmpty}
							bgColor={colors.Error}
							onClick={() => setShowResetMessage(false)}
						>
							Close
						</Button>
					</InputBlock>
				</Box>
			) : null}
			<Box style={{ width: '100%', maxWidth: '1000px' }}>
				<BoxDescription>Login</BoxDescription>
				<InputBlock gridColumns='1fr 1fr'>
					<InputWithLabel label='Email' required>
						<Input
							name='email'
							type='email'
							required
							value={login.email}
							onChange={handleInputChange}
							isError={isEmailError}
						/>
						<InputError isError={isEmailError}>
							{loginError(errorLogin)}
						</InputError>
					</InputWithLabel>
					<InputWithLabel label='Password' required>
						<Input
							name='password'
							type='password'
							value={login.password}
							onChange={handleInputChange}
							isError={isPasswordError}
						/>
						<InputError isError={isPasswordError}>
							{loginError(errorLogin)}
						</InputError>
					</InputWithLabel>
				</InputBlock>
				<ButtonBlock>
					<Button
						disabled={isFormEmpty}
						bgColor={colors.Success}
						onClick={loading ? () => {} : handleLogin}
					>
						{loading ? (
							<Fragment>
								<CircularProgress size={16} color='inherit' />
								Checking
							</Fragment>
						) : (
							'Login'
						)}
					</Button>
					<div
						style={{ color: 'blue', cursor: 'pointer' }}
						onClick={handleResetPassword}
					>
						Forgot Password
					</div>
				</ButtonBlock>
			</Box>
		</Fragment>
	) : (
		<CircularProgress />
	);
};
