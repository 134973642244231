import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';
import { input } from '../../../styles/input';

import 'flatpickr/dist/themes/light.css';

export const StyledFlatpickr = styled(Flatpickr)`
	${input.base}

	${({ isError }) => isError && input.error}
`;
