// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyDvgdlN9zqijPcZreRisGDkiAdsJRPxkOg',
	authDomain: 'ecb-pmy-dbs-checker.firebaseapp.com',
	projectId: 'ecb-pmy-dbs-checker',
	storageBucket: 'ecb-pmy-dbs-checker.appspot.com',
	messagingSenderId: '509641694757',
	appId: '1:509641694757:web:1188e7c8a27bd532eda9cc',
	measurementId: 'G-8FC45BPJQR',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
