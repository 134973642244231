export const LogoLettersECB = ({ className, ...props }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 98 39'
			fill='none'
			className={className}
			{...props}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.5483 16.1693C25.4328 17.202 25.3751 18.2245 25.3751 19.2741C25.3751 19.5017 25.3751 19.7327 25.3819 19.9637H11.4171V32.8449L29.5465 32.8279C30.569 34.2886 31.5881 35.6439 32.9061 36.8532H4.70815L4.71154 8.11521C4.71154 5.14969 3.27804 1.99734 0 1.44364V0.9375H33.5379C31.1499 3.01642 29.3903 5.26179 27.9432 8.08804C27.5967 5.44183 25.511 4.73186 22.9802 4.73186H11.4171V16.1761'
				fill='#FFFFFF'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M83.1875 33.0657H75.3168V19.9433H83.2555C87.5084 19.9705 90.5181 22.4265 90.5181 26.6658C90.5147 33.548 83.2045 33.0657 83.1875 33.0657M75.3134 4.40237H82.5489C86.1633 4.40237 88.5207 6.33523 88.5207 9.94616C88.5207 13.5605 86.221 16.1523 82.6169 16.1523H75.3168V4.40237H75.3134ZM68.6045 36.8532L86.4452 36.8465C92.6616 36.8465 97.2203 32.1383 97.2203 26.6726C97.2203 19.3896 91.1364 17.2428 90.1275 16.9336C92.9265 15.5885 94.8662 13.3329 94.8662 9.35849C94.8662 4.7047 91.0039 0.9375 84.9845 0.9375H63.8964V1.44364C67.1506 1.99734 68.6045 5.16668 68.6045 8.11521V36.8532Z'
				fill='#FFFFFF'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M63.4581 30.4671C59.7623 33.7757 56.3891 34.0033 51.6708 33.9557C43.7831 33.8844 37.1285 28.1809 37.1998 19.0806C37.1285 9.98697 43.7831 3.95743 51.6708 3.88609C57.1432 3.82495 62.6191 5.77478 63.5566 12.2459L63.8963 12.195V4.23597C60.0986 1.4301 55.4957 0 50.7774 0C37.8724 0 29.9882 8.76068 29.9882 19.2164C29.9882 29.6858 37.869 38.1679 50.7774 38.1679C55.4991 38.1679 60.0986 36.7276 63.8963 33.9218'
				fill='#FFFFFF'
			/>
		</svg>
	);
};
