import styled, { css } from 'styled-components';
import { colors } from '../../styles/colors';
import { input } from '../../styles/input';

export const Input = styled.input`
	${input.base}

	${({ isError }) => isError && input.error}
`;

export const InputError = styled.div`
	display: none;
	color: ${colors.Error};
	${({ isError }) =>
		isError &&
		css`
			display: block;
		`};
`;
