import { updatePassword } from 'firebase/auth';
import { collection, query, where } from 'firebase/firestore';
import { getCollectionWithQuery, updateDocument } from '../firebase/db';
import { firestore } from '../firebase/initialize';
import { FIRESTORE } from '../routes/routes';

/**
 * Get the user profile from the database
 * @returns An array of objects.
 */
export const getUserProfile = async uid => {
	const userProfileQuery = query(
		collection(firestore, FIRESTORE.USERS),
		where('uid', '==', uid)
	);
	const userProfile = await getCollectionWithQuery(userProfileQuery);

	return userProfile;
};

/**
 * It updates the user's password and other data
 * @param userRef - The user reference from the firebase.auth().currentUser object.
 * @param user - The user object that contains the user's data.
 */
export const updateUserProfilePassword = async (userRef, user) => {
	try {
		const { password, confirm_password, ...otherData } = user;
		await updatePassword(userRef, password);
		await updateDocument(FIRESTORE.USERS, otherData, user.user_id);
	} catch (error) {
		console.error(error);
	}
};
