import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { shadows } from '../../styles/shadows';

export const CenteredContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 48px;
`;

export const Box = styled.div`
	overflow: hidden;
	border-radius: 4px;
	background-color: #fff;

	${shadows.shadow1}
`;

export const BoxDescription = styled.div`
	padding: 12px 20px;
	background-color: ${colors.PrimaryLight1};
	color: #fff;
	font-size: 20px;
	font-weight: 600;
`;

export const InputBlock = styled.div`
	display: grid;
	padding: 20px;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-columns: ${({ gridColumns }) => gridColumns};
	grid-template-rows: auto;
`;

export const AddNewBlock = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	max-width: 1200px;

	a:link {
		text-decoration: none;
	}

	a:visited {
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
	}

	a:active {
		text-decoration: none;
	}
`;
