import { useNavigate } from 'react-router-dom';
import { LogoECB } from '../../../assets/icons/LogoECB';
import { ROUTES } from '../../../routes/routes';
import { Style } from './styles';

export const HomeContent = () => {
	const navigate = useNavigate();

	return (
		<Style>
			<LogoECB height='200px' />
			<h4>England & Wales Cricket Board</h4>
			<div className='info'>
				<h3>Welcome</h3>
				<h5>
					This ECB system contains information relating to DBS
					certificates for individuals involved in cricket. Access to
					this site is restricted to authorised users only.
				</h5>
				<h3>DBS Certificates</h3>
				<h5>
					Information and advice relating to DBS certificates is
					available on the{' '}
					<a
						rel='noreferrer'
						href='https://www.ecb.co.uk/safeguarding/dbs-checks-ecb-vetting'
						target='_blank'
					>
						ECB website
					</a>
					. If you have a query relating to DBS certificates please
					contact the ECB DBS Team via the Help button at the bottom
					of the screen.
				</h5>
				<h3>Safeguarding</h3>
				<h5>
					All of the ECB's safeguarding policies and guidance are
					available on the safeguarding section of the{' '}
					<a
						rel='noreferrer'
						href='https://www.ecb.co.uk/about/policies/safeguarding'
						target='_blank'
					>
						ECB website
					</a>
					. If you need to contact the ECB Safeguarding Team please{' '}
					<a href='mailto:safeguarding@ecb.co.uk'>click here</a>.
				</h5>
				<button onClick={() => navigate(ROUTES.LOGIN)}>Login</button>
			</div>
		</Style>
	);
};
