import { Button, ButtonBlock } from '../../../../components/Buttons/styles';
import {
	Box,
	BoxDescription,
	InputBlock,
} from '../../../../components/Containers/styles';
import { InputWithLabel } from '../../../../components/Inputs/InputWithLabel/InputWithLabel';
import { Input } from '../../../../components/Inputs/styles';
import { colors } from '../../../../styles/colors';

export const SearchUserForm = ({ clearFilter, setFilter, setValue, value }) => {
	return (
		<Box style={{ width: '100%', maxWidth: '1200px' }}>
			<BoxDescription>Search</BoxDescription>
			<InputBlock gridColumns='1fr 1fr'>
				<InputWithLabel label='Name'>
					<Input onChange={setValue} name='name' value={value.name} />
				</InputWithLabel>
				<InputWithLabel label='Email'>
					<Input
						onChange={setValue}
						type='email'
						name='email'
						value={value.email}
					/>
				</InputWithLabel>
			</InputBlock>
			<ButtonBlock>
				<Button bgColor={colors.Success} onClick={() => setFilter()}>
					Search
				</Button>
				<Button bgColor={colors.Error} onClick={clearFilter}>
					Reset
				</Button>
			</ButtonBlock>
		</Box>
	);
};
