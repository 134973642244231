import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const PaginateStyle = styled.div`
	ul {
		display: flex;
		align-items: center;
		gap: 0 16px;
		list-style-type: none;

		li {
			cursor: pointer;
		}

		.previous {
			background-color: ${colors.Primary1};
			padding: 8px;
			border-radius: 4px;
			color: white;

			&.disabled {
				background-color: ${colors.Neutral2};
				color: ${colors.Neutral4};
				cursor: not-allowed;
			}
		}

		.next {
			background-color: ${colors.Primary1};
			padding: 8px;
			border-radius: 4px;
			color: white;

			&.disabled {
				background-color: ${colors.Neutral2};
				color: ${colors.Neutral4};
				cursor: not-allowed;
			}
		}
		.selected {
			background-color: ${colors.Primary1};
			padding: 8px;
			border-radius: 4px;
			color: white;
		}
	}
`;
