import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const Main = styled.div`
	display: flex;
	height: 48px;
	padding: 8px 16px;
	justify-content: space-between;
	background-color: ${colors.Primary1};
`;

export const LinkContainer = styled.div`
	display: flex;
	grid-column-gap: 36px;
	align-items: center;

	.logo {
		height: 24px;
		cursor: pointer;
	}

	.icon {
		height: 36px;
		cursor: pointer;

		path {
			fill: #fff;
		}
	}
`;

export const LinkWithLabel = styled.div`
	a:link {
		text-decoration: none;
	}

	a:visited {
		text-decoration: none;
	}

	a:hover {
		text-decoration: none;
	}

	a:active {
		text-decoration: none;
	}

	.flex {
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: white;
	}
`;
