import { Dropdown } from './styles';

export const DropdownInput = ({
	data = [],
	value = '',
	name,
	onChange = () => {},
}) => {
	return (
		<Dropdown onChange={onChange} value={value} name={name}>
			<option value=''></option>
			{data.map((item, index) => (
				<option key={index} value={item.id}>
					{item.name}
				</option>
			))}
		</Dropdown>
	);
};
