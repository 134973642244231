import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Button, ButtonBlock } from '../../../../components/Buttons/styles';
import {
	Box,
	BoxDescription,
	CenteredContainer,
	InputBlock,
} from '../../../../components/Containers/styles';
import { DropdownInput } from '../../../../components/Dropdown/DropdownInput';
import { InputWithLabel } from '../../../../components/Inputs/InputWithLabel/InputWithLabel';
import { Input, InputError } from '../../../../components/Inputs/styles';
import {
	getCollection,
	getDocument,
	updateDocument,
} from '../../../../firebase/db';
import { FIRESTORE, ROUTES } from '../../../../routes/routes';
import { colors } from '../../../../styles/colors';
import { createUser } from '../../../../utils/users';
import { auth } from '../../../../firebase/initialize';
import { ERROR } from '../../../../constants/errors';
import { loginError } from '../../../../utils/errors';

export const UserForm = () => {
	const userObj = {
		county_id: 0,
		user_id: 0,
		role_id: 0,
		first_name: '',
		last_name: '',
		password: '',
		confirm_password: '',
		uid: '',
		is_active: true,
		user_email: '',
	};
	const navigate = useNavigate();
	const { id } = useParams();
	const [isFetching, setIsFetching] = useState(false);
	const [roles, setRole] = useState([]);
	const [counties, setCounties] = useState([]);
	const [errorLogin, setErrorLogin] = useState(null);
	const [user, setUser] = useState(userObj);

	const userAuth = auth.currentUser;
	const isUserEmail = userAuth && userAuth.uid === user.uid;
	const isPasswordEqual = user.confirm_password === user.password;
	const isEditFormFilled =
		isPasswordEqual &&
		user.first_name &&
		user.last_name &&
		user.county_id &&
		user.role_id;
	const isNewFormFilled =
		isEditFormFilled && user.confirm_password && user.password;
	const isEmailError = errorLogin === ERROR.EMAIL_ALREADY_USED;
	const isPasswordError = errorLogin === ERROR.WEAK_PASSWORD;

	useEffect(() => {
		setIsFetching(true);
		getCollection(FIRESTORE.ROLE)
			.then(status => {
				setIsFetching(false);
				setRole(_.sortBy(status, 'name'));
			})
			.catch(error => console.error(error));

		setIsFetching(true);
		getCollection(FIRESTORE.COUNTIES)
			.then(counties => {
				setIsFetching(false);
				setCounties(_.sortBy(counties, 'name'));
			})
			.catch(error => console.error(error));
	}, []);

	useEffect(() => {
		if (roles.length && counties.length && id !== 'new') {
			setIsFetching(true);
			getDocument(FIRESTORE.USERS, id, userObj)
				.then(doc => {
					setIsFetching(false);
					setUser({ ...user, ...doc });
				})
				.catch(error => {
					setIsFetching(false);
					console.error(error);
				});
		}
	}, [roles, counties, id]);

	const handleInputChange = event => {
		const { name, value } = event.target;
		name === 'role_id' || name === 'county_id'
			? setUser({ ...user, [name]: Number(value) })
			: setUser({ ...user, [name]: value });
	};

	const handleSave = () => {
		const { user_id, uid, password, confirm_password, ...otherData } = user;
		if (
			!user_id &&
			password &&
			confirm_password &&
			password === confirm_password
		) {
			return createUser(user)
				.then(() => navigate(-1))
				.catch(error => setErrorLogin(error.errorCode));
		}
		if (!password && !confirm_password && user_id && uid) {
			updateDocument(
				FIRESTORE.USERS,
				{ ...otherData, user_id, uid },
				user.user_id
			)
				.then(() => navigate(-1))
				.catch(error => setErrorLogin(error.errorCode));

			return;
		}
	};

	return (
		<CenteredContainer>
			,
			{isFetching ? (
				<CircularProgress />
			) : (
				<Box style={{ width: '100%', maxWidth: '1000px' }}>
					<BoxDescription>
						{id === 'new' ? 'New' : 'Edit'} User
					</BoxDescription>
					<InputBlock gridColumns='1fr 1fr'>
						<InputWithLabel label='First Name' required>
							<Input
								onChange={handleInputChange}
								value={user.first_name}
								name='first_name'
							/>
						</InputWithLabel>
						<InputWithLabel label='Last Name' required>
							<Input
								onChange={handleInputChange}
								value={user.last_name}
								name='last_name'
							/>
						</InputWithLabel>
						<InputWithLabel label='Email' required>
							<Input
								disabled={!isUserEmail && user.user_id}
								onChange={handleInputChange}
								type='email'
								value={user.user_email}
								name='user_email'
								required
								isError={isEmailError}
							/>
							<InputError isError={isEmailError}>
								{loginError(errorLogin)}
							</InputError>
						</InputWithLabel>
						<InputWithLabel label='Role' required={id === 'new'}>
							<DropdownInput
								onChange={handleInputChange}
								value={user.role_id}
								name='role_id'
								data={roles}
							/>
						</InputWithLabel>
						<InputWithLabel
							label='New Password'
							required={id === 'new'}
						>
							<Input
								disabled={user.user_id}
								onChange={handleInputChange}
								type='password'
								value={user.password}
								name='password'
								isError={!isPasswordEqual || isPasswordError}
							/>
							<InputError isError={isPasswordError}>
								{loginError(errorLogin)}
							</InputError>
							<InputError isError={!isPasswordEqual}>
								Password is not the same
							</InputError>
						</InputWithLabel>
						<InputWithLabel
							label='Password Confirmation'
							required={id === 'new'}
						>
							<Input
								disabled={user.user_id}
								onChange={handleInputChange}
								type='password'
								value={user.confirm_password}
								name='confirm_password'
								isError={!isPasswordEqual}
							/>
							<InputError isError={!isPasswordEqual}>
								Password is not the same
							</InputError>
						</InputWithLabel>
						<InputWithLabel label='County' required>
							<DropdownInput
								onChange={handleInputChange}
								value={user.county_id}
								name='county_id'
								data={counties}
							/>
						</InputWithLabel>
					</InputBlock>
					<ButtonBlock>
						<Button
							bgColor={colors.Success}
							onClick={handleSave}
							disabled={
								id === 'new'
									? !isNewFormFilled
									: !isEditFormFilled
							}
						>
							Save
						</Button>
						<Button
							bgColor={colors.Error}
							onClick={() => navigate('/' + ROUTES.USERS)}
						>
							Cancel
						</Button>
					</ButtonBlock>
				</Box>
			)}
		</CenteredContainer>
	);
};
