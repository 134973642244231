import { CustomError } from '../errors/errors';
import { auth } from '../firebase/initialize';

/**
 * Gets the Firebase authentication token for the current user.
 *
 * @async
 * @returns {Promise<{ token: string, headers: string }>} A Promise that resolves with an object containing the authentication token and headers when the authentication process is complete.
 * @throws {CustomError} Throws an error with status code 401 and message "auth/user-not-authenticated" if the user is not authenticated.
 */
export const getAuthToken = async () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			if (user) {
				auth.currentUser
					.getIdToken(true)
					.then(token => {
						resolve({ access_token: token });
					})
					.catch(error => reject(error));
			} else {
				reject(new CustomError(401, 'auth/user-not-authenticated'));
			}
			unsubscribe();
		});
	});
};
